<template>
  <div class="dashboard__columns" v-if="totalQuotations > 0">
    <div class="dashboard__column-left">
      <CardDealer
        v-for="(quotation, i) in quotations"
        :key="quotation.id"
        :class="quotationActive == i ? 'card-dealer--active' : ''"
        @click="onCardDealerClick(i)"
        :carImage="quotation.vehicle.car_photo"
      >
        <template v-slot:content-left v-if="quotation.vehicle">
          <h3 style="word-break: break-word">
            {{
              quotation.vehicle.plate
                ? quotation.vehicle.plate
                : quotation.vehicle.chassis
            }}
          </h3>
          <h4>
            {{ manufacturer(quotation.vehicle.manufacturer_id)?.name }}
            {{ quotation.vehicle.model ? ` - ${quotation.vehicle.model}` : '' }}
          </h4>
        </template>
        <template v-slot:content-right>
          <Price
            :time="quotation.timing_residue"
            :price="quotation.total_price.toFixed(2)"
          />
        </template>
        <template v-slot:footer>
          <Tag
            v-if="quotation.status == 'pending' || quotation.status == 'cancelled'"
            v-for="(row, i) in quotation.quotation_rows"
            :key="i"
            >{{ parentPart(row.part_id)?.name ?? 'Altro' }}</Tag
          ><br />
          <Tag
            class="tag__paid"
            style="display: inline-flex; flex-shrink: 0"
            v-if="quotation.status === 'paid' || quotation.status === 'complete'"
            ><icon icon="Devices---Cash-fill" size="1.25rem" /> Pagamento
            {{ quotation.payment_type }}</Tag
          >
          <Tag
            class="tag__to-pay"
            style="display: inline-flex; flex-shrink: 0"
            v-if="quotation.status === 'payment-pending'"
            ><icon icon="Finance---bank-card-2-fill" size="1.25rem" /> Da Pagare
            {{ quotation.payment_type }}</Tag
          >
          <Tag
            class="tag__to-ship ml-space-sm"
            style="display: inline-flex; flex-shrink: 0"
            v-if="quotation.status === 'paid'"
            ><icon icon="Maps---to-send-fill" size="1.25rem" /> Da Spedire</Tag
          >
          <Tag
            class="tag__shipped"
            style="display: inline-flex; flex-shrink: 0"
            v-if="quotation.status === 'complete'"
            ><icon icon="Maps--send-fill" size="1.25rem" />
            <span v-if="quotation.payment_type == 'online'">Spedito</span>
            <span v-else>Gestione offline</span>
          </Tag>
          <Tag
            class="tag__canceled"
            style="display: inline-flex; flex-shrink: 0"
            v-if="quotation.status === 'cancelled'"
            >Scaduto</Tag
          >
        </template>
      </CardDealer>
      <div class="dashboard__pagination">
        <Pagination
          v-if="totalPages > 1"
          :nPages="totalPages"
          @pageChanged="onPageChange"
        />
      </div>
    </div>
    <div class="dashboard__column-right" v-if="quotationActiveData">
      <h2>PREVENTIVO #{{ quotationActiveData.quotation_code }}</h2>
      <DashboardHeader>
        <template v-slot:content-left>
          <CarText :carImage="quotationActiveData.vehicle.car_photo">
            <template v-if="quotationActiveData && quotationActiveData.vehicle">
              <h3 style="word-break: break-word">
                {{
                  quotationActiveData.vehicle.plate
                    ? quotationActiveData.vehicle.plate
                    : quotationActiveData.vehicle.chassis
                }}
              </h3>
              <h4>
                {{ manufacturer(quotationActiveData.vehicle.manufacturer_id)?.name }}
                {{ quotationActiveData.vehicle.model ? ` - ${quotationActiveData.vehicle.model}` : '' }}
              </h4>
            </template>
          </CarText>
        </template>
        <template v-slot:content-center>
          <template v-if="quotationActiveData && quotationActiveData.vehicle">
            <div>Anno {{ quotationActiveData.vehicle.year_of_production }}</div>
            <div>km {{ getKmValue(quotationActiveData.vehicle.km_count) }}</div>
          </template>
        </template>
        <template v-slot:content-right>
          <template
            v-if="
              quotationActiveData.status !== 'pending' &&
              quotationActiveData.status == 'paid' &&
              quotationActiveData.payment_type == 'online' &&
              quotationActiveData.tracking_code == null
            "
          >
            <Btn
              class="btn__primary mb-space-sm"
              style="display: flex; text-align: left; padding: var(--space-sm)"
              @click="openModal()"
              ><icon icon="Maps---send--line" size="2.188rem" /> CONFERMA <br />
              SPEDIZIONE</Btn
            >
          </template>
          <div style="display: flex">
            <Tag
              class="tag__paid"
              style="display: inline-flex; flex-shrink: 0"
              v-if="
                quotationActiveData.status === 'paid' ||
                quotationActiveData.status === 'complete'
              "
            >
              <icon icon="Devices---Cash-fill" size="1.25rem" /> Pagamento
              {{ quotationActiveData.payment_type }}
            </Tag>
            <Tag
              class="tag__to-pay"
              style="display: inline-flex; flex-shrink: 0"
              v-if="quotationActiveData.status === 'payment-pending'"
            >
              <icon icon="Finance---bank-card-2-fill" size="1.25rem" /> Da Pagare
            </Tag>
            <Tag
              class="tag__to-ship ml-space-sm"
              style="display: inline-flex; flex-shrink: 0"
              v-if="quotationActiveData.status === 'paid'"
            >
              <icon icon="Maps---to-send-fill" size="1.25rem" /> Da Spedire
            </Tag>
            <Tag
              class="tag__shipped"
              style="display: inline-flex; flex-shrink: 0"
              v-if="
                quotationActiveData.status === 'complete' &&
                quotationActiveData.payment_type == 'online'
              "
            >
              <icon icon="Maps--send-fill" size="1.25rem" /> Spedito
            </Tag>
            <Tag
              class="tag__offlinepayment"
              style="display: inline-flex; flex-shrink: 0"
              v-if="quotationActiveData.payment_type === 'offline'"
            >
              <span v-if="quotationActiveData.payment_method_id == 2">Contrassegno</span>
              <span v-else>Bonifico</span>
            </Tag>
            <Tag
              class="tag__canceled"
              style="display: inline-flex; flex-shrink: 0"
              v-if="quotationActiveData.status === 'cancelled'"
            >
              Scaduto
            </Tag>
          </div>
          <div
            class="text--xs"
            style="text-align: right"
            v-if="quotationActiveData.payment_type == 'offline'"
          >
            Contattare il cliente per gestire il pagamento offline.
          </div>
          <div
            class="text--xs"
            style="text-align: right"
            v-if="
              quotationActiveData.status === 'complete' &&
              quotationActiveData.payment_type == 'online'
            "
          >
            <span v-if="quotationActiveData.tracking_code"
              >Codice spedizione: {{ quotationActiveData.tracking_code }}</span
            >
            <span v-else
              >Info spedizione: {{ quotationActiveData.tracking_message }}</span
            >
          </div>
        </template>
      </DashboardHeader>

      <div class="messages-info d-flex justify-between align-center">
        <div class="messages-info-cta" style="flex-grow: 1; flex-shrink: 1">
          <!-- Problemi con il preventivo?<br />
          Se scrivi al cliente per maggiori informazioni, il preventivo andrà in pausa in
          attesa della risposta! -->
        </div>
        <Comments
          :quantity="quotationActiveData.submission.message_count"
          @click="commentsVisible = !commentsVisible"
          :active="commentsVisible"
        />
      </div>

      <!--  CHAT -->
      <div class="mb-space-lg">
        <SubmissionMessages
          v-if="commentsVisible"
          :chatToken="quotationActiveData.submission.token_uuid"
        />
      </div>

      <h4 v-if="quotationActiveData.vehicle">
        Matrice Richiesta {{ quotationActiveData.quotation_code }}
      </h4>
      <div class="quotation__date">
        <div>
          Data
          {{
            formatData(
              quotationActiveData.accepted_date || quotationActiveData.created_at
            )
          }}
        </div>
      </div>
      <div
        v-if="
          quotationActiveData.status == 'payment-pending' ||
          quotationActiveData.status == 'paid' ||
          quotationActiveData.status == 'complete'
        "
      >
        <h3>Dati Cliente</h3>
        <div>
          <div>
            {{ quotationActiveData.user?.name }} {{ quotationActiveData.user.surname }}
          </div>
          <div v-if="quotationActiveData.user.fiscal_code">
            C.F {{ quotationActiveData.user.fiscal_code }}
          </div>
          <div v-if="quotationActiveData.user.vat_number">
            P.IVA {{ quotationActiveData.user.vat_number }}
          </div>
          <div class="contacts-data" v-if="quotationActiveData.user.email">
            Email
            <a :href="`mailto:${quotationActiveData.user.email}`">{{
              quotationActiveData.user.email
            }}</a>
          </div>
          <div class="contacts-data" v-if="quotationActiveData.user.phone">
            Tel.
            <a :href="`tel:${quotationActiveData.user.phone}`">{{
              quotationActiveData.user.phone
            }}</a>
          </div>
          <div v-if="quotationActiveData.user.adr_street">
            {{ quotationActiveData.user.adr_street }},
            {{ quotationActiveData.user.adr_house_no }}
          </div>
          <div>
            {{ quotationActiveData.user.adr_city }}
            {{ quotationActiveData.user.adr_zip_code }}
          </div>
        </div>
      </div>
      <div
        class="more-info"
        v-if="
          (quotationActiveData.notes && quotationActiveData.notes !== 'N/A') ||
          quotationActiveData.audio_urls ||
          quotationActiveData.audio_descriptions
        "
      >
        <template
          v-if="quotationActiveData.notes && quotationActiveData.notes !== 'N/A'"
        >
          <p class="mb-0"><strong>Note</strong></p>
          <p class="mt-0">{{ quotationActiveData.notes }}</p>
        </template>
        <template v-if="quotationActiveData.audio_urls">
          <p class="mb-0"><strong>Nota audio</strong></p>
          <audio controls :key="`audio_${quotationActiveData.id}`">
            <source :src="quotationActiveData.audio_urls" type="audio/wav" />
            Your browser does not support the audio tag.
          </audio>
        </template>
        <template v-if="quotationActiveData.audio_descriptions">
          <p class="mb-0"><strong>Trascrizione nota audio</strong></p>
          <p class="mt-0">{{ quotationActiveData.audio_descriptions }}</p>
        </template>
      </div>

      <h3>Lista Articoli:</h3>
      <template v-if="quotationActiveData">
        <template
          v-for="quotation in quotationActiveData.quotation_rows"
          :key="quotation.id"
        >
          <WhiteContainer
            class="white-container--no-radius p-space-xs mb-space-md"
            v-if="!($route.meta.type === 'accepted' && quotation.accepted === 0)"
          >
            <small>{{ breadcrumbs(quotation.part_id) }}</small>
            <div class="quotations__row">
              <div class="quotations__row-elements mb-space-sm mt-space-sm">
                <div>
                  <h6 class="text--xs mb-0 mt-0">Quantità</h6>
                  N°{{ quotation.quantity }}
                </div>
                <div>
                  <h6 class="text--xs mb-0 mt-0">OE Code</h6>
                  {{ quotation.part_code }}
                </div>
                <div>
                  <h6 class="text--xs mb-0 mt-0">ITEM Code</h6>
                  {{ quotation.item_code }}
                </div>
                <div>
                  <h6 class="text--xs mb-0 mt-0">Marca</h6>
                  {{ quotation.brand }}
                </div>
              </div>
              <div class="quotations__row-elements">
                <div class="text--sm">{{ getConditionLabel(quotation.condition) }}</div>
                <div class="text--sm">{{ quotation.notes }}</div>
              </div>
              <div class="quotations__row-elements quotations__row-elements--end">
                <div v-if="quotation.full_net_price" class="mr-space-xs">
                  <div class="text--xs">Prezzo listino netto</div>
                  <div>
                    <strong><del>€ {{ quotation.full_net_price.toFixed(2) }}</del></strong>
                  </div>
                </div>
                <div v-if="quotation.full_net_price && quotation.discount && quotation.discount > 0" class="mr-space-xs">
                  <div class="text--xs">Sconto</div>
                  <div>
                   <span v-if="quotation.discount && quotation.discount > 0"> <strong>{{ quotation.discount }}%</strong></span>
                   <span v-else> - </span>
                  </div>
                </div>
                <div class="mr-space-xs">
                  <div class="text--xs">Prezzo netto</div>
                  <div>
                    <strong>€ {{ quotation.net_price.toFixed(2) }}</strong>
                  </div>
                </div>
                <div>
                  <div class="text--xs">IVA</div>
                  <div>
                    <strong>{{ quotation.tax_import }}%</strong>
                  </div>
                </div>
              </div>
            </div>
          </WhiteContainer>
        </template>
      </template>
      <div class="quotations__total" v-if="quotationActiveData">
        <div>Totale netto € {{ quotationActiveData.subtotal_price.toFixed(2) }}</div>
        <div
          v-if="
            quotationActiveData.shipping_cost_flat &&
            (quotationActiveData.status == 'pending' ||
              quotationActiveData.shipping_fee_chosen == 'flat')
          "
        >
          Spedizione base € {{ quotationActiveData.shipping_cost_flat.toFixed(2) }}
        </div>
        <div
          v-if="
            quotationActiveData.shipping_cost_partial &&
            (quotationActiveData.status == 'pending' ||
              quotationActiveData.shipping_fee_chosen == 'partial')
          "
        >
          Spedizione parziale € {{ quotationActiveData.shipping_cost_partial.toFixed(2) }}
        </div>
        <div>IVA totale € {{ quotationActiveData.total_tax.toFixed(2) }}</div>
        <hr />
        <h3>Totale € {{ quotationActiveData.total_price.toFixed(2) }}</h3>
        <div class="text--xs" v-if="quotationActiveData.status == 'pending'">
          I costi di spedizione corretti verranno calcolati quando l'utente avrà accettato
          il preventivo.
        </div>
      </div>
      <PdfTemplate :pdfData="quotationActiveData" :isSubmission="false"></PdfTemplate>
    </div>
  </div>
  <div v-else>
    <h2 style="text-align: center">NON SONO PRESENTI DATI</h2>
  </div>
</template>

<script>
import Btn from "@/components/atoms/Btn.vue";
import CardDealer from "@/components/molecules/CardDealer.vue";
import Tag from "@/components/atoms/Tag.vue";
import Pagination from "@/components/atoms/Pagination.vue";
import DashboardHeader from "@/components/molecules/DashboardHeader.vue";
import CarText from "@/components/molecules/CarText.vue";
import Price from "@/components/atoms/Price.vue";
import WhiteContainer from "@/components/atoms/WhiteContainer.vue";
import Icon from "@/components/atoms/icon/Icon.vue";
import PdfTemplate from "@/components/organisms/PdfTemplate.vue";
import { mapState, mapGetters } from "vuex";
import timeMixin from "@/mixins/timeMixin";
import Comments from "@/components/atoms/Comments.vue";
import SubmissionMessages from "@/components/organisms/SubmissionMessages.vue";

export default {
  name: "Quotations",
  mixins: [timeMixin],
  components: {
    CardDealer,
    Tag,
    Pagination,
    DashboardHeader,
    CarText,
    Price,
    WhiteContainer,
    Btn,
    Icon,
    PdfTemplate,
    Comments,
    SubmissionMessages,
  },
  data() {
    return {
      commentsVisible: false,
    };
  },
  created() {
    this.$store.dispatch("resetSearchText");
    this.$store.dispatch("fetchQuotations", { filter: this.$route.meta.type, page: 1 });
  },
  watch: {
    $route() {
      this.$store.dispatch("resetSearchText");
      this.$store.dispatch("fetchQuotations", { filter: this.$route.meta.type, page: 1 });
    },
  },
  computed: mapState({
    quotations: (state) => state.quotations.data,
    quotationActive: (state) => state.quotations.quotationActive,
    totalPages: (state) => state.quotations.totalPages,
    ...mapGetters([
      "quotationActiveData",
      "totalQuotations",
      "dealerID",
      "parentPart",
      "manufacturer",
      "breadcrumbs",
      "getKmValue",
    ]),
  }),
  methods: {
    onCardDealerClick(i) {
      this.commentsVisible = false;
      this.$store.dispatch("changeQuotationActive", i);
    },
    onPageChange(newPage) {
      this.commentsVisible = false;
      this.$store.dispatch("fetchQuotations", {
        filter: this.$route.meta.type,
        page: newPage,
      });
    },
    openModal() {
      this.$store.dispatch("openModal", { component: "TrackingForm", data: [] });
    },
    fetchData() {
      this.$store.dispatch("fetchQuotations", { filter: this.$route.meta.type, page: 1 });
    },
    sortingData() {
      this.commentsVisible = false;
      this.$store.dispatch("sortingQuotations");
    },
    getConditionLabel(key) {
      const conditions = {
        new: "Nuovo",
        used: "Usato",
        reconditioned: "Ricondizionato",
      };

      return conditions[key] || "";
    },
  },
};
</script>
<style lang="scss" scoped>
.dashboard__column-right {
  h2 {
    text-align: center;
  }
}
.quotations {
  &__row-elements {
    display: flex;
    color: var(--text-color-light);
    * {
      flex-grow: 1;
    }

    &--end {
      justify-content: flex-end;
      text-align: center;
      * {
        flex-grow: 0;
      }
    }

    strong {
      color: #000;
    }
  }
  &__total {
    text-align: right;
    margin: var(--space-xl) 0 var(--space-md) 0;
    font-weight: bold;
    > *:not(:last-child) {
      margin-bottom: var(--space-sm);
    }
  }
}
.contacts-data {
  a,
  a:link {
    color: var(--color-primary-400);
  }
}
.messages-info {
  font-size: var(--text-xs);
}
.messages-info .messages-info-cta {
  color: var(--primary-color-400);
}
</style>
