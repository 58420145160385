<template>
	<div class="chat-thread" ref="scrollToMe">
    <div class="chat-thread__container mt-space-md" v-for="message in thread" :key="message.order" :class="message.is_client ? 'mr-auto' : 'ml-auto'">
      <div class="chat-thread__avatar">
        <Avatar v-if="message.is_client" class="avatar--tertiary-250 avatar--xs"/>
      </div>
      <div class="chat-thread__content">
        <div class="chat-thread__title">
          <h5>{{message.user_name}}</h5> &#9679; <small>{{fromNow(message.created_at)}}</small>
        </div>
        <div class="chat-thread__text">
          {{message.text}}
        </div>
        <div v-if="message.attachments" class="chat-thread__images" @click="viewDocument(message.attachments)">
          <img v-for="image in imagesJson(message.attachments)" :src="image" :alt="image" :key="image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/atoms/Avatar.vue';
import timeMixin from '@/mixins/timeMixin';

export default {
  name: 'MessageThread',
  mixins: [timeMixin],
  components: {
    Avatar,
  },
  props: {
    thread: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    scrollDown() {
      const el = this.$refs.scrollToMe;

      if (el) {
        el.scrollTop = el.scrollHeight;
      }
    },
    viewDocument(images) {
      this.$store.dispatch('openModal', { component: 'Lightbox', data: images });
    },
    imagesJson(images) {
      return JSON.parse(images);
    },
  },
  mounted() {
    this.scrollDown();
  },
  updated() {
    this.scrollDown();
  },
};
</script>

<style lang="scss" scoped>
.chat-thread {
  overflow-y: auto;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  padding: var(--space-sm);

  &__container {
    display: flex;
    background-color: var(--first-neutral-color-200);
    padding: var(--space-sm);
    border-radius: 1.25rem;
    box-shadow: .0625rem .0625rem .3125rem var(--gray-color-200);
    max-width: 70%;
  }
  &__avatar {
    padding-right: var(--space-sm);
  }
  &__text {
    font-size: var(--text-sm);
  }
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-xxs);
    h5 {
      margin: 0;
      padding-right: var(--space-xxs);
    }
    small {
      padding-left: var(--space-xxs);
    }
  }
  &__images {
    display: flex;
    flex-wrap: wrap;
    img {
      width: 6.25rem;
      height: 6.25rem;
      object-fit: cover;
      margin: var(--space-xxs);
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    padding: var(--space-sm) 0;
    > * {
      margin-left: var(--space-sm);
    }
  }
}
</style>
