<template>
  <div class="container-lg profile">
    <h1>Profilo</h1>
    <div class="row">
      <WhiteContainer class="col-3">
        <div class="p-space-md">
          <div class="text-center">
            <DealerAvatar class="m-auto" />
            <h2 class="mt-space-xs mb-auto">{{user.legal_name}}</h2>
            <StartsReview class="mb-space-md" :vote="user.dealer_stars"/>
            <Btn class="btn__primary" @click="logout()">Esegui Logout</Btn>
          </div>
          <Counter :number="user.credits" class="profile__counter" label="Crediti Residui"><Icon icon="Finance---coin-fill" size="1.875rem"/></Counter>
          <Counter :number="pingData.dealer_accepted_quotations_count" class="profile__counter" label="Preventivi Accettati"><Icon icon="Business---Preventivi-line" size="1.875rem"/></Counter>
          <Counter :number="pingData.dealer_sended_quotations_count" class="profile__counter" label="Preventivi Inviati"><Icon icon="Business---Preventivi-line" size="1.875rem"/></Counter>
        </div>
      </WhiteContainer>
      <WhiteContainer class="col-9">
        <div class="p-space-md">
          <section>
            <h2><Icon icon="Finance---coin-fill" size="1.375rem" class="mr-space-xs"/> I TUOI CREDITI</h2>
            <div class="row align-center ">
              <div class="col-6 text--xl">
                <strong>{{user.credits}}</strong> Crediti residui
              </div>
              <!-- <div class="col-4">
                <h4>RICARICA AUTOMATICA</h4>
              </div> -->
              <div class="col-6">
                <router-link :to="{name: 'purchase-credits'}"><Btn class="btn__primary btn__primary--small" style="display: inline-flex; align-items: center"><Icon icon="Finance---coin-fill" size="1.875rem" class="mr-space-sm"/> ACQUISTA CREDITI</Btn></router-link>
              </div>
            </div>
          </section>
            <section>
              <h2>ACCOUNT SETTINGS</h2>
              <div class="row">
                <div class="col-6">
                  <FormProfile class="mb-space-lg" title="IBAN" form="updateIban"/>
                  <div class="mb-space-lg">
                    <!-- <h3 class="mb-space-sm">METODO DI PAGAMENTO <br>PREDEFINITO<span class="ml-space-sm cursor-pointer" @click="editUserPayment = !editUserPayment"><Icon icon="Design---pencil-fill" size="1.125rem"/></span></h3> -->
                      <!-- <div v-if="user.current_payment_method">
                        <div v-if="user.current_payment_method.type == 'card'">
                          <em class="d-flex"><Icon icon="Finance---bank-card-2-fill" class="mr-space-xs" size="1.25rem"/> {{user.current_payment_method.card.brand}} {{user.current_payment_method.card.last4}}</em>
                        </div>
                        <div v-if="user.current_payment_method.type == 'sepa_debit'">
                          <em class="d-flex"><Icon icon="Finance---bank-card-2-fill" class="mr-space-xs" size="1.25rem"/> {{user.current_payment_method.sepa_debit.country}} {{user.current_payment_method.sepa_debit.last4}}</em>
                        </div>

                      </div>
                      <template v-if="editUserPayment">
                      <StripeUpdateCard class="mt-space-sm"/>
                    </template> -->
                  </div>
                  <FormProfile title="SPEDIZIONI" form="updateFee"/>
                </div>
                <div class="col-6">
                  <FormProfile class="mb-space-lg" title="METODO DI PAGAMENTO CLIENTI" form="updatePaymentMethods"/>
                  <FormProfile title="GEOLOCALIZZAZIONE" form="updateProvinces"/>
                </div>
              </div>
            </section>
            <section>
              <FormProfile title="I TUOI DATI" tagTitle="h2" iconSize="1.375rem" form="updateUserData"/>
            </section>
            <section>
              <h2>
                <Icon icon="Finance---money-euro-circle-line" size="1.375rem" class="mr-space-xs"/> IL TUO ABBONAMENTO
              </h2>
                <div class="row" v-if="user.current_subscription && user.current_subscription.status == 'active'">
                  <div class="col-6 text--lg">
                    <Icon class="text-secondary" icon="Systems---checkbox-circle-fill" size="1rem"/> Abbonamento Attivo
                  </div>
                  <div class="col-6">
                    <div><strong class="text--lg">{{formatData(user.current_subscription.ends_at)}}</strong> <small>Rinnovo mensile</small></div>
                    <div class="text--xs profile__unsubscribe" @click="openModal()">Disdici abbonamento</div>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-6 text--lg">
                    Abbonamento non attivo
                  </div>
                  <div class="col-6">
                    <div v-if="user.current_subscription && user.current_subscription.canceled_at">
                      <strong class="text--lg">{{formatData(user.current_subscription.canceled_at)}}</strong> 
                      <small>Data disdetta</small>
                    </div>
                  </div>
                </div>
            </section>
            <section>
              <div class="row">
                <div class="col-6">
                  <FormProfile title="CAMBIO PASSWORD" form="updatePassword"/>
                </div>
                <div class="col-6">
                  <h3 class="mt-0">Termini e condizioni</h3>
                  <a class="terms-link" :href="termConditionLink" target="_blank" >Visualizza termini e condizioni</a>
                </div>
              </div>
            </section>
        </div>
      </WhiteContainer>
    </div>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
import DealerAvatar from '@/components/molecules/DealerAvatar.vue';
import Btn from '@/components/atoms/Btn.vue';
import Counter from '@/components/molecules/Counter.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import StripeUpdateCard from '@/components/molecules/forms-profile/StripeUpdateCard.vue';
import FormProfile from '@/components/organisms/FormProfile.vue';
import StartsReview from '@/components/molecules/StarsReview.vue';

import { mapState } from 'vuex';
import timeMixin from '@/mixins/timeMixin';

export default {
  name: 'Profile',
  mixins: [timeMixin],
  components: {
    WhiteContainer,
    DealerAvatar,
    Btn,
    Counter,
    Icon,
    StripeUpdateCard,
    FormProfile,
    StartsReview,
  },
  data() {
    return {
      editUserPayment: false,
    };
  },
  created() {
    this.$store.dispatch('fetchUserData');
    this.$store.dispatch('fetchProvinceData');
    this.$store.dispatch('fetchPaymentMethodsData');
  },
  computed: {
    ...mapState({
      pingData: (state) => state.ping.data,
      user: (state) => state.user.data.user,
    }),
    termConditionLink() {
      return process.env.VUE_APP_TERM_CONDITION_LINK;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push({ name: 'login' });
    },
    openModal() {
      this.$store
        .dispatch('openModal', { component: 'Unsubscribe', data: [] });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  section {
    margin-bottom: var(--space-lg);
  }
  a {
    text-decoration: none;
  }
  &__counter {
    margin: var(--space-md) 0;
  }
  &__unsubscribe {
    cursor: pointer;
  }
}
a.terms-link {
  color: black;
  text-decoration: underline;
}
</style>
