export default {
  state: {
    data: [],
    modal: {
      isOpen: false,
      component: null,
    },
    lightbox: {
      currentImage: 0,
    },
    payment: {
      error: '',
    },
    laoding: false,
  },
  getters: {
    isLoading: (state) => state.loading,
  },
  mutations: {
    CHANGE_MODAL_STATUS(state, value) {
      state.modal.isOpen = value;
    },
    SET_MODAL_COMPONENT(state, component) {
      state.modal.component = component;
    },
    SET_MODAL_DATA(state, data) {
      state.data = data;
    },
    CHANGE_LIGHTBOX_CURRENT_IMAGE(state, index) {
      state.lightbox.currentImage = index;
    },
    SET_PAYMENT_ERROR(state, error) {
      state.payment.error = error;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    openModal({ commit }, payload) {
      commit('CHANGE_MODAL_STATUS', true);
      commit('SET_MODAL_COMPONENT', payload.component);
      commit('SET_MODAL_DATA', payload.data);
    },
    closeModal({ commit }) {
      commit('CHANGE_MODAL_STATUS', false);
    },
    changeLightboxImage({ commit }, index) {
      commit('CHANGE_LIGHTBOX_CURRENT_IMAGE', index);
    },
    paymentError({ commit }, error) {
      commit('SET_PAYMENT_ERROR', error);
    },
    setLoading({ commit }, payload) {
      commit('SET_LOADING', payload);
    },
  },
  modules: {
  },
};
