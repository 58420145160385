<template>
  <div class="dashboard-filters">
    <div class="dashboard-filters__header">
      <h3>Filtri</h3>
    </div>
    <div class="dashboard-filters__list dashboard-filters__list--primary">
      <div class="dashboard-filters__item">
        <CustomSelect label="Marchio Vettura" :options="manufacturerOptions" v-model="formData.manufacturers" @update:modelValue="applyFilters()"/>
      </div>
      <div class="dashboard-filters__item">
        <CustomSelect label="Tipologia ricambio" :options="partsOptions" v-model="formData.parts" @update:modelValue="applyFilters()"/>
      </div>
      <div class="dashboard-filters__item">
        <CustomSelect label="Provincia" :options="provinceOptions" v-model="formData.provinces" @update:modelValue="applyFilters()"/>
      </div>
      <div class="dashboard-filters__buttons">
        <!-- <Btn class="btn__secondary" @click="applyFilters()">Applica i filtri</Btn> -->
        <Btn class="btn__secondary" :disabled="activeCountFilters == 0" @click="clearFilters()">
          <icon class="mr-space-xxs" icon="Systems---filter-2-fill" size="1.25rem"/>
          Pulisci i filtri ({{ activeCountFilters }})
        </Btn>
        <!-- <Btn class="btn__secondary">Applica i filtri preferiti</Btn> -->
      </div>
    </div>
    <div class="dashboard-filters__list">
      <div class="dashboard-filters__list--single-line">
        <div class="dashboard-filters__item">
          <BaseInput
            type="date"
            label="Data da"
            :showLabel="false"
            v-model="formData.dateFrom"
            @onClear="applyFilters()"
            @update:modelValue="applyFilters()"
            :upperDateLimit="formData.dateTo"
          />
        </div>
        <div class="dashboard-filters__item">
          <BaseInput
            type="date"
            label="Data a"
            :showLabel="false"
            v-model="formData.dateTo"
            @onClear="applyFilters()"
            @update:modelValue="applyFilters()"
            :lowerDateLimit="formData.dateFrom"
          />
        </div>
      </div>
      <div>
        <Sort @onSortingData="sortingData" :sorting="isSorting" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomSelect from '@/components/atoms/CustomSelect.vue';
import Btn from '@/components/atoms/Btn.vue';
import Icon from '@/components/atoms/icon/Icon.vue';
import BaseInput from '../atoms/form/BaseInput.vue';
import Sort from '@/components/atoms/Sort.vue';
import dayjs from 'dayjs';

import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'DashboardFilters',
  components: {
    CustomSelect,
    Btn,
    Icon,
    BaseInput,
    Sort,
  },
  created() {
    this.$store.dispatch('fetchManufacturerData');
    this.$store.dispatch('fetchProvinceData');
    this.$store.dispatch('fetchParts');
    // this.applyFilters();
  },
  data() {
    return {
      formData: {
        manufacturers: [],
        parts: [],
        provinces: [],
        dateFrom: null,
        dateTo: null,
      },
    };
  },
  computed: {
    ...mapState({
      manufacturer: (state) => state.manufacturer.data,
      ...mapGetters([
        'manufacturerOptions',
        'provinceOptions',
        'partsOptions',
      ]),
      ...mapGetters({
        isSorting: 'isSorting',
      }),
    }),
    activeCountFilters(){
      let activeCount = 0;
      if(this.formData.manufacturers.length > 0){
        activeCount++;
      }
      if(this.formData.parts.length > 0){
        activeCount++;
      }
      if(this.formData.provinces.length > 0){
        activeCount++;
      }
      if(this.formData.dateFrom > 0){
        activeCount++;
      }
      if(this.formData.dateTo){
        activeCount++;
      }
      return activeCount;
    }
  },
  methods: {
    ...mapActions({
      setSort: 'dashboardFilters/setSort',
    }),
    clearFilters() {
      this.formData.manufacturers = [];
      this.formData.parts = [];
      this.formData.provinces = [];
      this.formData.dateFrom = null;
      this.formData.dateTo = null;
      this.$store.dispatch('resetDashboardFilters');
      this.$emit('onApplyFilters');
    },
    applyFilters() {
      this.$store.dispatch('setDashboardFilters', this.formData);
      this.$emit('onApplyFilters');
    },
    sortingData() {
      // this.$refs.dashboardPage?.sortingData();
      // this.$emit('onSortData');
      this.$store.dispatch('setSort');
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-filters {
  z-index: 100;
  &__header {
    padding: 0 var(--space-sm);
    margin: 0;
    & h3 {
      margin: 0;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-xs) var(--space-sm);

    &--single-line {
      display: flex;
    }
  }
  &__icon {
    align-self: center;
    width: var(--space-lg);
  }
  &__item {
    position: relative;
    // flex-grow: 1;
    &:not(:last-child) {
      margin-right: var(--space-xs);
    }
    &-counter {
      position: absolute;
    }
  }
  // Specific selector for the primary list
  &__list--primary .dashboard-filters__item {
    flex-grow: 1;
  }
  &__buttons {
    padding: var(--space-xs) var(--space-sm);
    > * {
      &:not(:last-child) {
        margin-right: var(--space-xs);
      }
    }
  }
}
</style>
