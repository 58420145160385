<template>
  <div class="submission-messages">
    <MessageThread class="px-space-md" :thread="messages"/>
    <CommentTextarea class="mt-space-md" @onSendMessage="sendMessage"/>
  </div>
</template>

<script>
import MessageThread from '@/components/molecules/MessageThread.vue';
import CommentTextarea from '@/components/atoms/form/CommentTextarea.vue';
import { mapState } from 'vuex';
// Socket
const { io } = require('socket.io-client');

export default {
  name: 'SubmissionMessages',
  components: {
    MessageThread,
    CommentTextarea,
  },
  data() {
    return {
      socket: null,
      messages: [],
    };
  },
  props: {
    chatToken: {
      type: String,
      default: '',
    },
  },
  computed: mapState({
    token: (state) => state.user.data.token,
  }),
  mounted() {
    try {
        this.socket = io(process.env.VUE_APP_SOCKET_URL, {
            secure: true,
            auth: {
                userToken: this.token,
                chatToken: this.chatToken,
            },
        });

        this.readMessage();

        this.socket.on("connect_error", error => {
            if(this.socket.active) {
                console.log("[ERROR] socket - Temporary failure, the socket will automatically try to reconnect", error.message);
            } else {
                console.log("[ERROR] socket - ", error.message);
            }
        });

        this.socket.on(`/message/${this.chatToken}`, (message) => {
            // check if message already exists before adding
            if (!this.messages.some( m =>
                m.text === message.text
                && m.created_at === message.created_at
                && m.timestamp === message.timestamp
            )) {
                this.messages.push(message);
                if (!message.my_message) this.readMessage();
            }
        });
    } catch (e) {
        console.error(e);
    }
  },
  unmounted() {
    if (this.socket) {
      this.socket.disconnect();
    }
  },
  methods: {
    sendMessage(message) {
      this.socket.emit(`/message/${this.chatToken}/newMessage`, {
        type: 'newMessage',
        text: message,
        attachments: [],
      });
    },

    readMessage() {
      this.socket.emit(`/message/${this.chatToken}/newMessage`, {
        type: 'confirmRead',
        text: '',
        attachments: [],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.submission-messages {
  // background-color: var(--secondary-neutral-color-300);
  border-radius: 0 0 30px 30px;
}
</style>
