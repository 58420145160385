<template>
  <div class="message-chat">
    <DashboardHeader v-if="submissionActiveData" class="message-chat__header">
      <template v-slot:content-left>
        <CarText :carImage="submissionActiveData.vehicle.car_photo">
        <template v-if="submissionActiveData">
          <h3 style="word-break: break-word">{{submissionActiveData.vehicle.plate ? submissionActiveData.vehicle.plate : submissionActiveData.vehicle.chassis }}</h3>
          <h4>
            {{manufacturer(submissionActiveData.vehicle.manufacturer_id)?.name}}
            {{ submissionActiveData.vehicle.model ? ` - ${submissionActiveData.vehicle.model}` : '' }}
          </h4>
        </template>
        </CarText>
      </template>
      <template v-slot:content-right>
        <template v-if="submissionActiveData">
          <div>Anno {{submissionActiveData.vehicle.year_of_production}}</div>
          <div>Km {{getKmValue(submissionActiveData.vehicle.km_count)}}</div>
        </template>
        <Btn v-if="submissionActiveData.vehicle.registration_document" @click="viewDocument(submissionActiveData.vehicle.registration_document)" class="mt-space-xs btn__tertiary btn__tertiary--small" style="display: inline-flex">
          <Icon icon="Systems---eye-fill" size="1rem"/> Vedi libretto
        </Btn>
      </template>
    </DashboardHeader>
    <ChatThread :thread="messages"/>
    <CommentTextarea class="mt-auto" @onSendMessage="sendMessage"/>
  </div>
</template>

<script>
import CommentTextarea from '@/components/atoms/form/CommentTextarea.vue';
import ChatThread from '@/components/molecules/ChatThread.vue';
import DashboardHeader from '@/components/molecules/DashboardHeader.vue';
import CarText from '@/components/molecules/CarText.vue';
import Btn from '@/components/atoms/Btn.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

import { mapState, mapGetters } from 'vuex';
// Socket
const { io } = require('socket.io-client');

export default {
  name: 'SubmissionMessages',
  components: {
    CommentTextarea,
    ChatThread,
    DashboardHeader,
    CarText,
    Btn,
    Icon
  },
  data() {
    return {
      socket: null,
      messages: [],
      messageTimeout: null,
    };
  },
  props: {
    chatToken: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: null,
    },
  },
  computed: mapState({
    ...mapGetters([
      'submissionActiveData',
      'getKmValue',
      'manufacturer',
    ]),
    token: (state) => state.user.data.token,
  }),
  unmounted() {
    if (this.socket) {
      this.socket.disconnect();
    }
    clearTimeout(this.messageTimeout);
  },
  methods: {
    sendMessage(message) {
        if(this.socket && this.socket.connected){
            this.socket.emit(`/message/${this.chatToken}/newMessage`, {
                type: 'newMessage',
                text: message,
                attachments: [],
            });
            this.$store.dispatch('fetchMessagesData');
        }
    },
    getMessages() {
        try {
            if (this.id) {
                this.$store.dispatch('fetchSubmission', this.id);
            }

            this.messages = [];

            if (this.socket) {
                this.socket.disconnect();
            }

            if (this.token && this.chatToken) {
                this.socket = io(process.env.VUE_APP_SOCKET_URL, {
                    secure: true,
                    auth: {
                        userToken: this.token,
                        chatToken: this.chatToken,
                    },
                });

                this.readMessage();

                this.socket.on("connect_error", error => {
                    if(this.socket.active) {
                        console.log("[ERROR] socket - Temporary failure, the socket will automatically try to reconnect", error.message);
                    } else {
                        console.log("[ERROR] socket - ", error.message);
                    }
                });

                this.socket.on(`/message/${this.chatToken}`, (message) => {
                    // check if message already exists before adding
                    if (!this.messages.some( m =>
                        m.text === message.text
                        && m.created_at === message.created_at
                        && m.timestamp === message.timestamp
                    )) {
                        this.messages.push(message);

                        // Reset the timeout each time a new message is received
                        clearTimeout(this.messageTimeout);
                        this.messageTimeout = setTimeout(() => {
                            this.$store.dispatch('fetchMessagesData');
                            this.$emit('new-message', this.chatToken);
                        }, 1000);
                    }
                });
            }
        } catch (e) {
            console.error(e);
        }
    },
    viewDocument(images) {
      this.$store.dispatch('openModal', { component: 'Lightbox', data: images });
    },
    readMessage() {
      this.socket.emit(`/message/${this.chatToken}/newMessage`, {
        type: 'confirmRead',
        text: '',
        attachments: [],
      });
    },
  },
  watch: {
    chatToken() {
      this.getMessages();
    },
  },
  mounted() {
    this.getMessages();
  },
};
</script>
<style scoped lang="scss">
.message-chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__header {
    border-bottom: 1px solid var(--gray-color-300);
  }
}
</style>
