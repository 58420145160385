<template>
  <div class="container-lg messages">
    <div class="messages__content">
      <h1>Messaggi</h1>
      <div class="row messages__full-height pb-space-sm">
        <WhiteContainer class="col-6" style="height: 100%; overflow: hidden">
          <div class="p-space-md messages__wrapper">
            <h4>Messaggi</h4>
            <div class="messages__list">
              <div class="mt-space-md" v-for="(message, index) in messages" :key="message.id" :class="{'notify--active' : messageActive == message.uuid}" @click="changeChat(index, message)">
                <Notify :title="message.topic_title" :content="message.last_message.text" :time="message.last_message.created_at" :read="message.last_message.read || message.last_message.user_id == user.id"/>
              </div>
            </div>
          </div>
        </WhiteContainer>
        <WhiteContainer class="col-6" style="height: 100%">
          <div class="p-space-md" style="height: 100%">
            <MessageChat :chatToken="uuid" :id="submissionID" @new-message="handleNewMessage"/>
          </div>
        </WhiteContainer>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteContainer from '@/components/atoms/WhiteContainer.vue';
import Notify from '@/components/atoms/Notify.vue';
import MessageChat from '@/components/organisms/MessageChat.vue';
import { mapState } from 'vuex';

export default {
  name: 'Messages',
  components: {
    WhiteContainer,
    Notify,
    MessageChat,
  },
  data() {
    return {
      messageActive: null,
      messageTimeout: null,
    };
  },
  created() {
    this.$store.dispatch('fetchManufacturerData');
    this.$store.dispatch('fetchMessagesData').then(() => {
      if(!this.messageActive) {
        this.messageActive = this.messages[0]?.uuid;
      }
      if (this.messages.length > 0) {
        const submissionID = this.messages.find(m => m.uuid === this.messageActive)?.submission_id;
        // this.$store.dispatch('fetchSubmission', this.messages[this.messageActive]?.submission_id);
        this.$store.dispatch('fetchSubmission', submissionID);
        this.$store.dispatch('setMessageRead', this.messages[0].uuid);
      }
    });

    // Timeout to check for new messages every xx seconds
    this.messageTimeout = setInterval(() => {
        this.$store.dispatch('fetchMessagesData');
    }, 20000);

  },
  computed: mapState({
    messages: (state) => state.messages.data,
    uuid() {
      // return this.messages[this.messageActive]?.uuid;
      return this.messageActive;
    },
    submissionID() {
      return this.messages.find(m => m.uuid === this.messageActive)?.submission_id;
      // return this.messages[this.messageActive]?.submission_id;
    },
    ...mapState({
      user: (state) => state.user.data.user,
    }),
  }),
  unmounted() {
    clearTimeout(this.messageTimeout);
  },
  methods: {
    changeChat(index, message) {
      this.messageActive = message.uuid;
      this.$store.dispatch('setMessageRead', message.uuid);
    },
    handleNewMessage(uuid){
      if(uuid === this.messageActive) {
        this.$store.dispatch('setMessageRead', this.messageActive);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.messages {
  &__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__list {
    height: 100%;
    overflow-y: auto;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 4.375rem - var(--space-lg));
    overflow: hidden;
  }

  &__full-height {
    flex-grow: 1;
    overflow: hidden;
  }
}
</style>
